import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ValuesPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Our Values" />
    <h1 style={styles.header}>Our Values</h1>
    <div style={styles.body}>
      <h3 style={{...styles.italic, ...styles.primaryText}}>Our Core Values are the Foundation of Your Investments</h3>
      <p>
        At the Coastal Advisory Group, Core Values are not rhetoric. They’re the rudder for everything we do as 
        financial advisors. Our values do not change just because the market does, nor do we reinvent them for each 
        situation. They guide our company and the people who work here.
      </p>
      <p>
        Because we maintain these Core Values, we offer something unique and valuable to existing and new clients when 
        it comes to financial planning. And more importantly, clients respond positively to us because of our dedication 
        to keeping our values as the foundation of all we do.
      </p>
      <p>
        <span style={{fontWeight: 600}}>These Core Values Are the Soul of Our Company:</span>
      </p>
      <ul>
        <li>
          We prosper when you prosper.
        </li>
        <li>
          All we have is who we are. And who we are is represented in everything we do and say.
        </li>
        <li>
          We do the right thing.
        </li>
        <li>
          We treat everyone from a place of integrity and commitment.
        </li>
        <li>
          When possible, we improve the lives of the people in our life, both clients and friends. And we have fun doing it.
        </li>
        <li>
          We plan ahead because what we do affects not only your life, but also the lives of future generations.
        </li>
        <li>
          When it comes to The Golden Rule, we save the exemptions (and excuses) for the "taxman". We strive to 
          indeed do unto others as we want done unto us.
        </li>
        <li>
          We are honest with ourselves - and with you.
        </li>
        <li>
          We keep our word.
        </li>
        <li>
          We have passion for what we do because what we do affects your life and those of future generations.
        </li>
        <li>
          We know that great friends come from mutual respect. Happy, successful clients and client relationships 
          come from the exact same place.
        </li>
        <li>
          We are present for the moment because we know it passes all too quickly.
        </li>
        <li>
          We are proactive and positioned to move on a moment's notice.
        </li>
        <li>
          We take time to see and enjoy the wonders of the world. We have hobbies. Interests. Passions. We know that 
          living an enriched life will, in turn, enrich the lives of our clients.
        </li>
      </ul>
      <a href="/philosophy">See how we put our core values to work for you.</a>
    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default ValuesPage
